<script>
import feather from 'feather-icons';
export default {
	components: {  },
	data() {
		return {
			theme: '',
			socials: [
				{
					id: 1,
					name: 'GitHub',
					icon: 'github',
					url: 'https://github.com/didiermun',
				},
				{
					id: 2,
					name: 'Twitter',
					icon: 'twitter',
					url: 'https://twitter.com/mun_didier',
				},
				{
					id: 4,
					name: 'Instagram',
					icon: 'instagram',
					url: 'https://instagram.com/mun_didier',
				},
			],
		};
	},
	mounted() {
		feather.replace();
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="container mx-auto ">
		<div
			class=""
		>
			<!-- Footer social links -->
			<div
				class="flex flex-col justify-center items-center mb-12 sm:mb-20"
			>
				<div class="block md:flex gap-4 w-full justify-center">
					<div class="flex flex-col w-full md:w-2/5">
						<div class="flex gap-2">
							<img
								src="@/assets/images/logo.png"
								class="w-16"
								alt="Logo"
							/>
							<p class="text-primary-dark dark:text-ternary-light text-3xl pt-4 font-bold">Ibiza Token</p>
						</div>
						<p class="text-primary-dark dark:text-ternary-light">
							Ibiza Token is a Web3 ecosystem that counts on the utility token $IBZ, the NFT marketplace, and the IbizaPay mobile application and aims to turn Ibiza into a “crypto island” benchmark by connecting local businesses and artists to a global audience and increasing the adoption of blockchain technology by citizens and institutions.
						</p>
					</div>

					<div class="flex flex-col w-full md:w-2/5 mt-7 px-5">
						<div class="flex flex-col px-6 w-full justify-start">
							<p class="text-primary-dark dark:text-ternary-light text-xl font-semibold text-left">Follow Us</p>
							<div class="flex gap-4 mt-3">
								<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M2.25 18H9V11.8125H6.75V9H9V6.75C9 5.85489 9.35558 4.99645 9.98851 4.36351C10.6215 3.73058 11.4799 3.375 12.375 3.375H14.625V6.1875H13.5C12.8789 6.1875 12.375 6.12891 12.375 6.75V9H15.1875L14.0625 11.8125H12.375V18H15.75C16.3465 17.9993 16.9184 17.762 17.3402 17.3402C17.762 16.9184 17.9993 16.3465 18 15.75V2.25C17.9994 1.65345 17.7621 1.08152 17.3403 0.659695C16.9185 0.237872 16.3465 0.000620434 15.75 0H2.25C1.65326 0 1.08097 0.237053 0.659011 0.659009C0.237054 1.08097 0 1.65326 0 2.25V15.75C0.000620434 16.3465 0.237872 16.9185 0.659695 17.3403C1.08152 17.7621 1.65345 17.9994 2.25 18Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
								<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M9 18C10.78 18 12.5201 17.4722 14.0001 16.4832C15.4802 15.4943 16.6337 14.0887 17.3149 12.4442C17.9961 10.7996 18.1743 8.99002 17.8271 7.24419C17.4798 5.49836 16.6226 3.89472 15.364 2.63604C14.1053 1.37737 12.5016 0.520204 10.7558 0.172937C9.00998 -0.17433 7.20038 0.00389957 5.55585 0.685088C3.91131 1.36628 2.50571 2.51983 1.51677 3.99987C0.527841 5.47991 0 7.21997 0 9C0 11.387 0.94821 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM4.11797 8.80501L12.7969 5.45907C13.1995 5.31375 13.5511 5.5575 13.4208 6.16641L11.9428 13.125C11.8336 13.6186 11.5402 13.7386 11.13 13.5061L8.88 11.8481L7.79484 12.8934C7.67484 13.0134 7.57359 13.1147 7.34109 13.1147L7.5 10.8281L11.6719 7.06079C11.8533 6.90094 11.6311 6.81141 11.3906 6.97032L6.23766 10.2127L4.01625 9.51985C3.53391 9.36657 3.52359 9.03751 4.11797 8.80501Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
								<svg width="24" height="24" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16.1503 3.74057C16.8779 3.20716 17.5047 2.54231 18 1.77856C17.3228 2.081 16.6067 2.28224 15.8738 2.37615C16.6463 1.90634 17.224 1.16225 17.4975 0.284808C16.7741 0.724608 15.9827 1.03436 15.1575 1.20067C14.6516 0.64654 13.9948 0.261268 13.2727 0.094913C12.5505 -0.0714419 11.7962 -0.0111898 11.1078 0.267839C10.4194 0.546867 9.82879 1.03178 9.41261 1.65956C8.99642 2.28735 8.77394 3.02901 8.77406 3.78817C8.77099 4.07901 8.79976 4.36929 8.85984 4.65355C7.39172 4.57949 5.95535 4.18858 4.64457 3.50636C3.33379 2.82414 2.17809 1.86597 1.25297 0.694424C0.777896 1.52701 0.630747 2.51423 0.841587 3.45436C1.05243 4.39449 1.60534 5.21656 2.38734 5.75258C1.80315 5.73647 1.23129 5.57653 0.719999 5.28624V5.32807C0.720975 6.20205 1.01548 7.04902 1.55393 7.72641C2.09239 8.4038 2.84193 8.87026 3.67641 9.04729C3.36075 9.13278 3.03537 9.17465 2.70891 9.17181C2.47452 9.17601 2.24037 9.15442 2.01047 9.10738C2.48859 10.6059 3.84609 11.7083 5.45859 11.7444C4.15194 12.7916 2.54091 13.3596 0.882657 13.3579C0.58756 13.3583 0.292739 13.3393 0 13.3011C1.68747 14.4162 3.65372 15.0062 5.66109 14.9997C12.1734 14.9997 16.4175 9.42758 16.1503 3.74057Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
								<svg width="24" height="24" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M21.5129 2.28589L21.5415 2.4706C21.4163 2.00106 21.1765 1.57209 20.8448 1.22428C20.513 0.876463 20.1003 0.621307 19.6457 0.482954L19.6268 0.478249C17.9104 1.32953e-05 11.009 1.29995e-05 11.009 1.29995e-05C11.009 1.29995e-05 4.12484 -0.00939846 2.39234 0.478249C1.93708 0.6134 1.52287 0.865407 1.18877 1.21049C0.854671 1.55558 0.61171 1.98237 0.482804 2.4506L0.47822 2.4706C-0.162302 5.90648 -0.166885 10.0359 0.506866 13.7147L0.47822 13.5282C0.603588 13.9978 0.843457 14.4267 1.17517 14.7746C1.50687 15.1225 1.91948 15.3778 2.37401 15.5165L2.39349 15.5206C4.10651 16 11.0102 16 11.0102 16C11.0102 16 17.8932 16 19.6263 15.5206C20.0818 15.3858 20.4963 15.1339 20.8307 14.7888C21.165 14.4437 21.4081 14.0167 21.5369 13.5482L21.5415 13.5282C21.8509 11.7744 22.0043 9.99532 21.9999 8.21295C21.9999 8.14413 21.9999 8.07472 21.9999 8.00472C21.9999 7.93472 21.9999 7.86413 21.9999 7.78825C22.0009 5.94274 21.8379 4.10098 21.5129 2.28589ZM8.80729 11.4359V4.57237L14.5519 8.00942L8.80729 11.4359Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
								<svg width="24" height="24" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M18.4042 1.76299L20 0.316283V0H14.4714L10.5318 9.29062L6.04896 0H0.252603V0.316283L2.11667 2.44243C2.20639 2.51963 2.27592 2.61555 2.31976 2.7226C2.3636 2.82964 2.38055 2.94489 2.36927 3.05921V11.4158C2.39682 11.5643 2.38678 11.7169 2.33997 11.861C2.29316 12.0051 2.21091 12.1366 2.1 12.2447L0 14.6546V14.9674H5.95417V14.6507L3.85417 12.2447C3.74177 12.137 3.65719 12.0062 3.60704 11.8624C3.5569 11.7186 3.54254 11.5657 3.5651 11.4158V4.18865L8.79167 14.9724H9.39896L13.8932 4.18865V12.7796C13.8932 13.0066 13.8932 13.053 13.737 13.201L12.1224 14.6812V15H19.9661V14.6837L18.4073 13.238C18.3399 13.1897 18.2876 13.1249 18.2562 13.0505C18.2248 12.9762 18.2155 12.8951 18.2292 12.8161V2.18437C18.2151 2.10546 18.2243 2.02441 18.2556 1.95008C18.2869 1.87576 18.3392 1.81104 18.4068 1.76299H18.4042Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
								<svg width="24" height="24" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g clip-path="url(#clip0_156_176)">
									<path d="M7.50235 3.64844C6.74113 3.64844 5.99699 3.87416 5.36406 4.29706C4.73112 4.71997 4.2378 5.32106 3.9465 6.02432C3.65519 6.72758 3.57897 7.50143 3.72747 8.24801C3.87598 8.99459 4.24255 9.68037 4.78082 10.2186C5.31909 10.7569 6.00488 11.1234 6.75148 11.2719C7.49808 11.4204 8.27196 11.3442 8.97524 11.0529C9.67852 10.7616 10.2796 10.2683 10.7025 9.6354C11.1255 9.00248 11.3512 8.25837 11.3512 7.49716C11.3512 6.47642 10.9457 5.49748 10.2239 4.7757C9.50209 4.05393 8.52313 3.64844 7.50235 3.64844ZM7.50235 9.99514C7.00782 9.99514 6.52439 9.84847 6.11322 9.57369C5.70206 9.29891 5.38163 8.90836 5.19247 8.45144C5.0033 7.99452 4.95391 7.49177 5.05053 7.00678C5.14715 6.52178 5.38545 6.07634 5.73528 5.7268C6.0851 5.37726 6.53074 5.13931 7.01583 5.04307C7.50091 4.94683 8.00364 4.99662 8.46042 5.18613C8.9172 5.37565 9.30751 5.69637 9.58198 6.10774C9.85645 6.51911 10.0027 7.00264 10.0024 7.49716C10.0025 7.82539 9.93785 8.15043 9.81223 8.45367C9.6866 8.75691 9.50243 9.03242 9.27023 9.26442C9.03804 9.49643 8.76239 9.68038 8.45904 9.80576C8.15569 9.93115 7.83059 9.9955 7.50235 9.99514Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M10.5925 0.0468811C9.2124 -0.0171797 5.79443 -0.0140548 4.41318 0.0468811C3.19912 0.103911 2.12881 0.398434 1.26552 1.26013C-0.177053 2.70267 0.00732086 4.64793 0.00732086 7.49708C0.00732086 10.4142 -0.155178 12.313 1.26552 13.7336C2.71357 15.1813 4.68545 14.9918 7.50224 14.9918C10.3929 14.9918 11.3901 14.9938 12.4116 14.5981C13.8011 14.0586 14.8499 12.8169 14.9507 10.5861C15.0155 9.20563 15.0116 5.78814 14.9507 4.40692C14.8269 1.77418 13.4136 0.176956 10.5905 0.0468811H10.5925ZM12.7769 12.7809C11.8311 13.7266 10.5194 13.6403 7.48427 13.6403C4.35927 13.6403 3.10615 13.6868 2.1917 12.77C1.13857 11.722 1.32802 10.0384 1.32802 7.48692C1.32802 4.03389 0.973728 1.54723 4.43935 1.3695C5.23545 1.34138 5.46982 1.33044 7.47451 1.33044L7.50263 1.34919C10.8331 1.34919 13.4464 1.00037 13.603 4.46552C13.6386 5.25612 13.6468 5.49362 13.6468 7.49474C13.6468 10.5833 13.705 11.8442 12.7757 12.7778L12.7769 12.7809Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M12.4031 3.49607C12.4031 3.67376 12.3504 3.84746 12.2517 3.99521C12.153 4.14295 12.0127 4.2581 11.8485 4.3261C11.6843 4.3941 11.5037 4.41189 11.3294 4.37723C11.1551 4.34256 10.995 4.25699 10.8694 4.13135C10.7437 4.0057 10.6582 3.84562 10.6235 3.67135C10.5888 3.49707 10.6066 3.31643 10.6746 3.15226C10.7426 2.9881 10.8578 2.84779 11.0055 2.74907C11.1533 2.65035 11.327 2.59766 11.5047 2.59766C11.743 2.59766 11.9715 2.69231 12.14 2.8608C12.3085 3.02928 12.4031 3.2578 12.4031 3.49607Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									</g>
									<defs>
									<clipPath id="clip0_156_176">
									<rect width="15" height="15" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									</clipPath>
									</defs>
								</svg>
								<svg width="24" height="24" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.52455 12.3645H9.95054L9.54798 11.0782L10.5104 11.8934L11.3927 12.674L13 14V1.44375C12.9597 0.663177 12.2791 0 11.4352 0L1.5275 0.00182247C0.684346 0.00182247 0 0.666093 0 1.44667V10.92C0 11.7432 0.68324 12.3645 1.52455 12.3645ZM7.75753 3.31516L7.73796 3.32208L7.74497 3.31516H7.75753ZM3.25 4.05526C4.33321 3.27724 5.33739 3.31443 5.33739 3.31443L5.41827 3.39318C4.09278 3.70526 3.4919 4.28969 3.4919 4.28969C3.4919 4.28969 3.65256 4.2124 3.93508 4.09391C5.72037 3.4012 7.66963 3.45115 9.47486 4.32833C9.47486 4.32833 8.87287 3.78146 7.62827 3.43182L7.73906 3.325C7.91079 3.325 8.82079 3.35708 9.79469 4.06C9.79469 4.06 10.8842 5.8975 10.8842 8.155C10.8472 8.11198 10.2083 9.12698 8.56153 9.16198C8.56153 9.16198 8.2827 8.85026 8.08401 8.57864C9.04719 8.30557 9.40764 7.75943 9.40764 7.75943C9.0915 7.95594 8.80307 8.07297 8.56892 8.18927C8.2092 8.34568 7.84875 8.4226 7.48903 8.50135C5.78463 8.77443 4.82994 8.31906 3.92401 7.95448L3.61045 7.80208C3.61045 7.80208 3.97017 8.34896 4.89494 8.62094C4.6523 8.89474 4.41188 9.20427 4.41188 9.20427C2.76546 9.16563 2.16531 8.15062 2.16531 8.15062C2.16531 5.89021 3.24815 4.05562 3.24815 4.05562L3.25 4.05526Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M7.8639 7.45007C8.28418 7.45007 8.62617 7.0997 8.62617 6.6684C8.62617 6.2371 8.28566 5.88965 7.8639 5.88965C7.44546 5.88965 7.10273 6.23819 7.10163 6.66986C7.10052 7.10152 7.44361 7.44824 7.8639 7.44824V7.45007Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M5.13352 7.45007C5.55343 7.45007 5.89579 7.0997 5.89579 6.6684C5.89579 6.2371 5.55602 5.88965 5.1361 5.88965H5.13352C4.71324 5.88965 4.37125 6.23819 4.37125 6.66986C4.37125 7.10152 4.71324 7.44824 5.13352 7.44824V7.45007Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>

								<svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M13.9964 14.0001H14V8.86491C14 6.3533 13.4593 4.41699 10.5226 4.41699C9.11095 4.41699 8.16376 5.19173 7.77694 5.926H7.73611V4.65288H4.95178V14.0001H7.85095V9.36986C7.85095 8.15142 8.08209 6.97309 9.5911 6.97309C11.0782 6.97309 11.1001 8.36361 11.1001 9.44788V14.0001H13.9964Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M3.13396 4.65332H0.23114V14.0001H3.13396V4.65332Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
									<path d="M1.68109 0C1.2353 0.000193236 0.807819 0.17737 0.492595 0.492595C0.17737 0.807819 0.000193236 1.2353 0 1.68109C0 2.60932 0.753229 3.37823 1.68109 3.37823C2.60896 3.37823 3.36219 2.60932 3.36219 1.68109C3.3619 1.23533 3.18469 0.807904 2.86949 0.4927C2.55428 0.177497 2.12686 0.00028974 1.68109 0Z" :fill="theme === 'light' ? '#00032A' : '#FFF'"/>
								</svg>
							</div>
						</div>
						<div class="flex flex-col px-6 mt-12">
							<p class="text-primary-dark dark:text-ternary-light text-xl font-semibold text-left">Subscribe To The Newsletter</p>
							<div class="flex gap-4 mt-3">
									<input
										class="w-full px-5 py-2 bg-primary-light border-none text-primary-dark dark:text-secondary-light rounded-md shadow-sm text-md"
										id="email"
										name="email"
										type="text"
										required=""
										placeholder="Email"
										aria-label="Email"
									/>
									<button class="px-4 py-2 rounded-md text-white  bg-primary-dark dark:bg-secondary-dark">Subscribe</button>
							</div>
						</div>
					</div>
					
				</div>
				
				
			</div>
		</div>
	</div>
</template>

<style scoped></style>
