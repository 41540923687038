<script>
import Farming from "../components/Farming.vue";
export default {
	name: 'Home',
	components: {
		Farming,
	},
};
</script>

<template>
	<div class="container mx-auto mt-8">
		<Farming/>
	</div>
</template>

<style scoped></style>
