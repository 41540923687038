<script>
export default {
	props: {
		theme: {
			type: String,
			required: true,
		},
	},
	methods: {
		toggleTheme() {
			const newTheme = this.theme === 'light' ? 'dark' : 'light';
			localStorage.setItem('theme', newTheme);
			this.$emit('themeChanged', newTheme);
			this.$router.go();
		},
	},
};
</script>

<template>
	<a href="#" @click.prevent="toggleTheme" aria-label="Theme Switcher">

		<button v-if="theme === 'dark'">
			<svg width="34" height="34" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_156_209)">
				<path d="M22.9671 10C20.2876 10 17.6684 10.7945 15.4405 12.2832C13.2126 13.7718 11.4762 15.8876 10.4508 18.3631C9.42546 20.8385 9.15717 23.5625 9.6799 26.1904C10.2026 28.8184 11.4929 31.2323 13.3876 33.127C15.2822 35.0216 17.6961 36.3119 20.3241 36.8346C22.952 37.3573 25.676 37.0891 28.1514 36.0637C30.6269 35.0383 32.7427 33.3019 34.2314 31.074C35.72 28.8462 36.5145 26.2269 36.5145 23.5475C36.5145 19.9544 35.0872 16.5086 32.5466 13.968C30.0059 11.4273 26.5601 10 22.9671 10ZM22.9671 32.5791C21.1808 32.5791 19.4346 32.0494 17.9493 31.057C16.4641 30.0646 15.3065 28.654 14.6229 27.0037C13.9393 25.3534 13.7605 23.5374 14.109 21.7855C14.4574 20.0335 15.3176 18.4242 16.5807 17.1611C17.8438 15.898 19.4531 15.0378 21.2051 14.6894C22.957 14.3409 24.773 14.5197 26.4233 15.2033C28.0736 15.8869 29.4842 17.0445 30.4766 18.5297C31.469 20.015 31.9987 21.7612 31.9987 23.5475C31.9987 25.9428 31.0472 28.24 29.3534 29.9338C27.6596 31.6276 25.3624 32.5791 22.9671 32.5791Z" fill="white"/>
				<path d="M8.75432 6.15254L5.56116 9.3457L10.0795 13.864L13.2727 10.6709L8.75432 6.15254Z" fill="white"/>
				<path d="M35.8505 33.2473L32.6573 36.4404L37.1757 40.9588L40.3688 37.7656L35.8505 33.2473Z" fill="white"/>
				<path d="M37.1863 6.14768L32.668 10.666L35.8612 13.8592L40.3795 9.34085L37.1863 6.14768Z" fill="white"/>
				<path d="M10.0914 33.2209L5.57309 37.7393L8.76626 40.9324L13.2846 36.4141L10.0914 33.2209Z" fill="white"/>
				<path d="M7.16167 21.29H0.387939V25.8059H7.16167V21.29Z" fill="white"/>
				<path d="M45.5461 21.29H38.7724V25.8059H45.5461V21.29Z" fill="white"/>
				<path d="M25.225 0.96875H20.7091V7.74248H25.225V0.96875Z" fill="white"/>
				<path d="M25.225 39.3535H20.7091V46.1272H25.225V39.3535Z" fill="white"/>
				</g>
				<defs>
				<clipPath id="clip0_156_209">
				<rect width="45.1582" height="45.1582" fill="white" transform="translate(0.387939 0.96875)"/>
				</clipPath>
				</defs>
			</svg>
		</button>
		<button v-else>
			<svg width="34" height="34" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M20.4729 40.3897C15.1907 40.3897 10.1249 38.2913 6.38989 34.5563C2.65485 30.8212 0.556519 25.7554 0.556519 20.4733C0.556519 15.1911 2.65485 10.1253 6.38989 6.39028C10.1249 2.65524 15.1907 0.556911 20.4729 0.556911C22.4988 0.546295 24.5142 0.848603 26.4478 1.45315C26.8293 1.57656 27.1647 1.81224 27.41 2.12936C27.6554 2.44648 27.7993 2.83027 27.823 3.23052C27.8466 3.63078 27.749 4.02886 27.5427 4.37269C27.3364 4.71652 27.0312 4.99008 26.6669 5.15759C25.6332 5.61713 24.7269 6.32134 24.0262 7.20943C23.3255 8.09752 22.8514 9.14279 22.6449 10.255C22.4385 11.3673 22.5058 12.513 22.8411 13.5934C23.1764 14.6738 23.7696 15.6564 24.5695 16.4563C25.3695 17.2562 26.352 17.8494 27.4324 18.1847C28.5128 18.52 29.6586 18.5874 30.7708 18.3809C31.8831 18.1744 32.9283 17.7004 33.8164 16.9997C34.7045 16.299 35.4087 15.3927 35.8683 14.359C36.0396 14.0168 36.3059 13.7313 36.6353 13.5365C36.9647 13.3417 37.3432 13.246 37.7256 13.2607C38.1079 13.2754 38.478 13.4 38.7914 13.6194C39.1049 13.8389 39.3485 14.1441 39.493 14.4984C40.0976 16.432 40.3999 18.4474 40.3893 20.4733C40.3893 25.7554 38.2909 30.8212 34.5559 34.5563C30.8209 38.2913 25.755 40.3897 20.4729 40.3897V40.3897ZM20.4729 4.54019C17.3216 4.54019 14.2411 5.47465 11.6209 7.2254C9.00075 8.97615 6.95857 11.4646 5.75263 14.376C4.54669 17.2873 4.23116 20.491 4.84594 23.5817C5.46073 26.6724 6.97821 29.5114 9.20649 31.7397C11.4348 33.968 14.2738 35.4855 17.3645 36.1002C20.4552 36.715 23.6588 36.3995 26.5702 35.1936C29.4816 33.9876 31.97 31.9454 33.7208 29.3252C35.4715 26.7051 36.406 23.6246 36.406 20.4733V19.9555C34.3037 21.6775 31.6364 22.5565 28.9222 22.4219C26.208 22.2873 23.6407 21.1486 21.7192 19.227C19.7976 17.3054 18.6589 14.7382 18.5243 12.024C18.3896 9.30981 19.2687 6.64248 20.9907 4.54019H20.4729Z" fill="#05074B"/>
			</svg>

		</button>
	</a>
</template>
